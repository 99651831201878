import React, { useState } from 'react';
import {
  array,
  bool,
  func,
  object,
  oneOf,
  PropTypes,
  string,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { injectIntl, intlShape } from '../../util/reactIntl';

import { parse } from 'url';
import { shape } from 'prop-types';
import { unionWith } from 'lodash';
import {
  shopByBrands,
  renterData,
  lenderData,
  exploreTheMarketPlace,
  productReview,
  displayCategories,
} from '../../marketplace-custom-config';

import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  SectionMarketPlace,
  SectionExploreMarketPlace,
  SectionRentYourStuff,
  SectionFaq,
  SectionCategory,
  SectionDiscount,
  SectionTrendSearch,
  SectionPopularItem,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
  isScrollingDisabled,
  manageDisableScrolling,
} from '../../ducks/UI.duck';
import {
  createSearchResultSchema,
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
} from '../SearchPage/SearchPage.helpers';
import MainPanelLanding from '../SearchPage/MainPanelLanding';
import facebookImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/yogatimeFacebook-1200x630.jpg';
import qrcode from '../../assets/qrcode.png';
import popupBg from '../../assets/desktop-popup-bg.svg';

import css from './LandingPage.module.css';
import { stringify } from '../../util/urlHelpers';
import LandingPageV2 from '../LandingPageV2/LandingPageV2';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export const LandingPageComponent = props => {
  const {
    history,
    intl,
    location,
    scrollingDisabled,
    currentUserListing,
    currentUserListingFetched,
    listings,
    isAuthenticated,
    sortConfig,
    onManageDisableScrolling,
    filterConfig,
    searchParams,
    searchInProgress,
    searchListingsError,
    onActivateListing,
    pagination,
    currentUser,
    viewport,
  } = props;

  const [showList, setshowList] = useState(false);

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' });
  const schemaDescription = intl.formatMessage({
    id: 'LandingPage.schemaDescription',
  });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const { mapSearch, page, ...searchInURL } = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });
  // urlQueryParams doesn't contain page specific url params
  // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
  const urlQueryParams = pickSearchParamsOnly(
    searchInURL,
    filterConfig,
    sortConfig
  );

  // Page transition might initially use values from previous search
  const urlQueryString = stringify(urlQueryParams);
  const paramsQueryString = stringify(
    pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
  );
  const searchParamsAreInSync = urlQueryString === paramsQueryString;

  const validQueryParams = validURLParamsForExtendedData(
    searchInURL,
    filterConfig
  );

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout =
    isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

  const { address, bounds, origin } = searchInURL || {};
  const { title, description, schema } = createSearchResultSchema(
    [],
    address,
    intl
  );

  const onReload = () => {
    setTimeout(
      () => typeof window !== 'undefined' && window.location.reload(false),
      1000
    );
    clearTimeout();
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      {!isAuthenticated ? (
        // (
        //   <LayoutSingleColumn>
        //     <LayoutWrapperTopbar>
        //       <TopbarContainer className={css.topBarLoginBefore} active="landing" />
        //     </LayoutWrapperTopbar>
        //     <LayoutWrapperMain>
        //       <div className={css.heroContainer}>
        //         <SectionHero
        //           className={css.hero}
        //           history={history}
        //           location={location}
        //           productReview={productReview}
        //         />
        //       </div>

        //       <ul className={css.sections}>
        //         <li className={css.section}>
        //           <div className={css.sectionContentFirstChild}>
        //             {/* Rent & Save  */}
        //             <SectionLocations />
        //             {/* Trending Search  */}
        //             <SectionMarketPlace shopByBrands={shopByBrands} viewport={viewport} />
        //           </div>
        //         </li>
        //         <li className={css.section}>
        //           <div className={css.sectionContent}>
        //             {/* How it works  */}
        //             <SectionHowItWorks
        //               lenderData={lenderData}
        //               renterData={renterData}
        //               currentUserListing={currentUserListing}
        //               currentUserListingFetched={currentUserListingFetched}
        //             />
        //           </div>
        //         </li>
        //       </ul>

        //       <div className={css.sections}>
        //         <SectionExploreMarketPlace
        //           exploreTheMarketPlace={exploreTheMarketPlace} viewport={viewport}
        //         />
        //       </div>

        //       <div className={css.sections}>
        //         <SectionRentYourStuff viewport={viewport} />
        //       </div>

        //       <div className={css.sections}>
        //         <SectionFaq viewport={viewport} />
        //       </div>
        //     </LayoutWrapperMain>
        //     <LayoutWrapperFooter>
        //       <Footer />
        //     </LayoutWrapperFooter>
        //   </LayoutSingleColumn>
        // )
        <LandingPageV2 />
      ) : (
        <div>
          <TopbarContainer active="landing" />
          <div className={css.LandingPageAfter}>
            {/* Search bar form */}
            <MainPanelLanding
              urlQueryParams={validQueryParams}
              history={history}
              location={location}
              // onReload={onReload}
              searchInProgress={searchInProgress}
              searchListingsError={searchListingsError}
              searchParamsAreInSync={searchParamsAreInSync}
              onActivateListing={onActivateListing}
              showMap={() => setshowList(true)}
              isLandingPage={true}
              onManageDisableScrolling={onManageDisableScrolling}
              searchParamsForPagination={parse(location.search)}
              showAsModalMaxWidth={MODAL_BREAKPOINT}
              currentUser={currentUser}
              showLists={() => setshowList(false)}
              showListState={showList}
            />

            {/* Discount section */}
            <SectionDiscount />

            {/* Category Section */}
            <SectionCategory
              displayCategories={displayCategories}
              viewport={viewport}
            />

            {/* Trend Search */}
            <SectionTrendSearch
              shopByBrands={shopByBrands}
              viewport={viewport}
            />

            {/* Popular items */}
            {listings && listings.length ? (
              <SectionPopularItem
                viewport={viewport}
                listings={listings}
                displayCategories={displayCategories}
              />
            ) : null}
          </div>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </div>
      )}
      {!isMobileLayout && <DesktopPopup />}
    </Page>
  );
};

const { number } = PropTypes;

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  currentUserListingFetched: false,

  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

LandingPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  pagination: propTypes.pagination,
  searchInProgress: bool.isRequired,
  onActivateListing: func.isRequired,

  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,

  // from withRouter
  // history: object.isRequired,
  // location: object.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,
  // from injectIntl
  intl: intlShape.isRequired,
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }),
};

const mapStateToProps = state => {
  const {
    currentUserListing,
    currentUserListingFetched,
    currentUser,
  } = state.user;
  const {
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
  } = state.SearchPage;
  const { currentPageResultIds } = state.LandingPage;
  const { isAuthenticated } = state.Auth;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(
      currentPageResultIds,
      searchMapListingIds,
      (id1, id2) => id1.uuid === id2.uuid
    )
  );

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUser,
    mapListings,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    listings: pageListings,
    currentUserListingFetched,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

const DesktopPopup = () => {
  const [show, setShow] = useState(true);
  return (
    <>
      {show && (
        <div
          style={{
            position: 'fixed',
            width: '100%',
            height: '100vh',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 999999999,
          }}
          className={css.desktopModal}
        >
          <div
            style={{
              width: '50%',
              minWidth: '600px',
              maxWidth: '1000px',
              backgroundColor: '#22B6A4',
              backgroundImage: `url(${popupBg})`,
              backgroundPosition: 'right',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              borderRadius: '20px',
              padding: '20px',
            }}
          >
            <div>
              <span
                style={{
                  width: '30px',
                  height: '30px',
                  marginLeft: 'auto',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  color: '#22B6A4',
                  borderRadius: '50%',
                  fontSize: '20px',
                  paddingBottom: '3px',
                  fontWeight: 'bold',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                  cursor: 'pointer',
                }}
                onClick={() => setShow(false)}
              >
                x
              </span>
            </div>
            <div
              style={{
                textAlign: 'center',
                width: '85%',
                margin: 'auto',
                padding: '25px 0px',
                fontWeight: 500,
                fontSize: '20px',
                color: '#060531',
              }}
            >
              The desktop version is under maintenance and some functions may
              not work properly. To access{' '}
              <span style={{ fontWeight: 'bold' }}>Runtize</span> on your mobile
              device, please scan the{' '}
              <span style={{ fontWeight: 'bold' }}>QR code</span> below.
            </div>
            <div
              style={{
                width: '130px',
                margin: 'auto',
                marginTop: '20px',
                marginBottom: '50px',
              }}
            >
              <img src={qrcode} width="100%" alt="QR Code" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPage;
