import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import css from './LandingPageV2.module.css';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { unionWith } from 'lodash';
import {
  isScrollingDisabled,
  manageDisableScrolling,
} from '../../ducks/UI.duck';
import { setActiveListing } from '../SearchPage/SearchPage.duck';
import classNames from 'classnames';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withViewport } from '../../util/contextHelpers';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { TopbarSearchForm } from '../../forms';
import { Button, Footer, IconClose, NamedLink } from '../../components';
import { FaGift } from 'react-icons/fa6';

import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import Banner from '../../components/Banner/Banner';
import FAQ from '../EarnPage/FAQ';
import Explore from '../EarnPage/Explore';
import HowItWorks from '../EarnPage/HowItWorks';

const LandingPageV2Component = props => {
  const { rootClassName, className, history } = props;
  const classes = classNames(
    rootClassName || css.root,
    className,
    'main__area'
  );
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = `${process.env.PUBLIC_URL}/static/assets/js/main.js`;
    scriptElement.async = true;
    document.body.appendChild(scriptElement);
    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  const handleSubmit = values => {
    const keywords = values;
    history.push(
      createResourceLocatorString(
        'SearchPage',
        routeConfiguration(),
        {},
        keywords
      )
    );
  };

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={handleSubmit}
      landingHero={true}
      useSearchIcon={true}
    />
  );

  return (
    <div className={classes}>
      <Banner />
      <TopbarContainer active="landing" />
      <section className="hero__area">
        <div className="container">
          <div className="row g-4 align-items-center">
            <div className="col-lg-6">
              <div
                className="her__content"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <h1 style={{ lineHeight: '1.2', marginBottom: '15px' }}>
                  The Renting <br /> <span>Marketplace</span>
                </h1>
                <p style={{ marginTop: '0', marginBottom: '16px' }}>
                  <span className="hide-on-mobile">Browse and rent</span>
                  <span className="show-on-mobile">Rent</span>
                  items delivered{' '}
                  <span className="hero__text__shape position-relative d-inline-block">
                    <img
                      className="position-absolute"
                      src={`${process.env.PUBLIC_URL}/static/assets/img/hero-text-shape.png`}
                      alt="text shape"
                    />{' '}
                    directly
                  </span>{' '}
                  to you
                </p>

                <div className="hero__search__area">
                  {/* <form action="#">
                    <input type="text" placeholder='Try "Drone" or "Ladder"' />
                    <button type="button">
                      <i className="far fa-search"></i>
                    </button>
                  </form> */}
                  {search}
                </div>

                <div className="hero__popular__area">
                  <p>Popular :</p>
                  <div className="hero__popular__btn">
                    <Link
                      to="/s?pub_category=tools"
                      className="popular__btn tools_btn"
                    >
                      Tools
                    </Link>
                    <Link
                      to="/s?pub_category=electronics"
                      className="popular__btn electronics_btn"
                    >
                      Electronics
                    </Link>
                    <Link
                      to="/s?pub_category=micromobility"
                      className="popular__btn micromobility_btn"
                    >
                      Micromobility{' '}
                    </Link>
                    <Link
                      to="/categoryPage"
                      className="popular__btn locations_btn"
                    >
                      All categories
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="hero__thumb"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/assets/img/hero_thumb.webp`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* fearures section */}
      <section className="service__area">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 col-md-6">
              <div
                className="service__wrapper"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <div className="service__ico">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/rent_icon_1.svg`}
                    alt=""
                  />
                </div>
                <div className="service__content">
                  <h4>Rent & Save</h4>
                  <p>
                    From tools and essentials to tech
                    <br />
                    gadgets and new experiences, rent save and access more
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="service__wrapper"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="1200"
              >
                <div className="service__ico">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/rent_icon_2.svg`}
                    alt=""
                  />
                </div>
                <div className="service__content">
                  <h4>Monetize & Earn</h4>
                  <p>
                    List an item. Snap some pictures,
                    <br />
                    set your price, and start earning. It's that simple
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div
                className="service__wrapper"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="1500"
              >
                <div className="service__ico">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/rent_icon_3.svg`}
                    alt=""
                  />
                </div>
                <div className="service__content">
                  <h4>Protect The Environment</h4>
                  <p>
                    Though we're not gonna end climate change on our own. Play
                    your part. Reduce waste
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* trending section */}
      <section className="tranding__area">
        <div
          className="container"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <div className="section__title">
            <h3 style={{ lineHeight: '1.2' }}>
              Trending <span>Searches</span>
            </h3>
          </div>
          <div className="tranding__inner__blk owl-carousel">
            <div className="single__tranding__blk">
              <Link to="/s?pub_subCategory=vrHeadsets">
                <div className="tranding__thumb">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/tranding_thumb_1.jpeg`}
                    alt=""
                  />
                </div>
                <div className="tranding__content">
                  <h4>Virtual Reality</h4>
                </div>
              </Link>
            </div>
            <div className="single__tranding__blk">
              <Link to="/s?pub_subCategory=saws">
                <div className="tranding__thumb">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/tranding_thumb_5.jpeg`}
                    alt=""
                  />
                </div>
                <div className="tranding__content">
                  <h4>Circular Saw</h4>
                </div>
              </Link>
            </div>
            <div className="single__tranding__blk">
              <Link to="/s?pub_subCategory=pressureWashers">
                <div className="tranding__thumb">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/tranding_thumb_3.jpeg`}
                    alt=""
                  />
                </div>
                <div className="tranding__content">
                  <h4>Pressure Washer</h4>
                </div>
              </Link>
            </div>
            <div className="single__tranding__blk">
              <Link to="/s?pub_subCategory=drones">
                <div className="tranding__thumb">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/tranding_thumb_2.jpeg`}
                    alt=""
                  />{' '}
                </div>
                <div className="tranding__content">
                  <h4>Drone</h4>
                </div>
              </Link>
            </div>
            <div className="single__tranding__blk">
              <Link to="/s?pub_subCategory=laptopsTablets">
                <div className="tranding__thumb">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/tranding_thumb_6.jpeg`}
                    alt=""
                  />
                </div>
                <div className="tranding__content">
                  <h4>Laptop</h4>
                </div>
              </Link>
            </div>
            <div className="single__tranding__blk">
              <Link to="/s?pub_subCategory=drills">
                <div className="tranding__thumb">
                  <img
                    src={`${process.env.PUBLIC_URL}/static/assets/img/tranding_thumb_4.jpeg`}
                    alt=""
                  />
                </div>
                <div className="tranding__content">
                  <h4>Drill</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* how it works section */}

      <HowItWorks />

      {/* explore section */}
      <Explore />
      {/* chart section */}
      <section className="rent__area">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-6 order-lg-2">
              <div
                className="rent__thumb"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/static/assets/img/remt_thumb.svg`}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 order-lg-1">
              <div
                className="rent__title"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <h4 style={{ lineHeight: '1.2' }}>
                  Rent your stuff, <span>Earn</span> an effortless side{' '}
                  <span>income</span>
                </h4>
              </div>
              <div
                className="rent__list"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <ul>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/assets/img/check_ico.svg`}
                        alt=""
                      />
                    </span>{' '}
                    Earn money doing (almost) nothing. List stuff you already
                    own
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/assets/img/check_ico.svg`}
                        alt=""
                      />
                    </span>{' '}
                    Do it confidently. Verified users and protection guarantee
                    of up to $20,000
                  </li>
                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/assets/img/check_ico.svg`}
                        alt=""
                      />
                    </span>{' '}
                    Smooth process. Takes less than 3 minutes to list an item
                  </li>

                  <li>
                    <span>
                      <img
                        src={`${process.env.PUBLIC_URL}/static/assets/img/check_ico.svg`}
                        alt=""
                      />
                    </span>{' '}
                    No worries keeping an open tab. We'll notify you when you
                    get a booking request
                  </li>
                </ul>
              </div>
              <div
                className="rent__btn"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <Link to="/earn">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Q & A section */}
      <FAQ />
      {/* Footer Section */}
      <Footer />
    </div>
  );
};

const mapStateToProps = state => {
  const {
    currentUserListing,
    currentUserListingFetched,
    currentUser,
  } = state.user;
  const {
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
  } = state.SearchPage;
  const { currentPageResultIds } = state.LandingPage;
  const { isAuthenticated } = state.Auth;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(
      currentPageResultIds,
      searchMapListingIds,
      (id1, id2) => id1.uuid === id2.uuid
    )
  );

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUserListing,
    currentUser,
    mapListings,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    listings: pageListings,
    currentUserListingFetched,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

const LandingPageV2 = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageV2Component);

export default LandingPageV2;
